import EventBus from './Events'
export function VideoYt(){
  this.events= {
    "INTERACTION.CLICK": true,
    "INTERACTION.HOVER": false,
    "INTERACTION.DRAG": false
  }
  this.eventType= 'INTERACTION.CLICK';
  this.inputs = {
    srcFbx: null,
    srcVideo: null,
    srcImage: null,
    srcTextVideoTitle: null,
    srcIcon: null,
    localPosition: null,
    localScale: null,
    boxshow: false,
    srcTextNext: null,
    linkTo: null,
    srcTextNextIcon: null,
    open: null,
    text: null,
    movieTo: null
  };
  this.outputs = {
    texture: null,
    painter: null
  }

  this.onInit = function() {
    const THREE = this.context.three;
    var loader = new THREE.FBXLoader();
    console.log(this.inputs.srcFbx)
    loader.load(this.inputs.srcFbx, (obj) => {

      var textureText = new THREE.TextureLoader().load(this.inputs.srcTextVideoTitle);
      textureText.mapping = THREE.ClampToEdgeWrapping;

      var textureIcon = new THREE.TextureLoader().load(this.inputs.srcIcon);
      textureIcon.mapping = THREE.ClampToEdgeWrapping;

      var textureImage = new THREE.TextureLoader().load(this.inputs.srcImage);
      textureImage.mapping = THREE.ClampToEdgeWrapping;

      const m1 = new THREE.MeshBasicMaterial({color: 0x000000,opacity:0.6, transparent:true}); //Sfondo
      const m2 = new THREE.MeshBasicMaterial({map: textureText,transparent:true}); //Titolo
      const m3 = new THREE.MeshBasicMaterial({map: textureIcon,transparent:true}); //Icona
      const m4 = new THREE.MeshBasicMaterial({map: textureImage,transparent:true}); //Immagine
      
      var materiali = new Array(m1,m2,m3,m4);
      var i = 0;
      obj.traverse( function ( child ) {
        if ( child.isMesh ) {
          child.material= materiali[i];
          i++
        }
      })

      obj.position.x = this.inputs.localPosition.x
      obj.position.y = this.inputs.localPosition.y
      obj.position.z = this.inputs.localPosition.z
      obj.scale.x = this.inputs.localScale.x
      obj.scale.y = this.inputs.localScale.y
      obj.scale.z = this.inputs.localScale.z

      obj.quaternion.copy( this.context.camera.quaternion );
    
      this.outputs.objectRoot = obj;
      this.outputs.collider = obj;
    });
  };
  this.onEvent = function(payload,data) {
    if(payload=='INTERACTION.CLICK'){
      console.log(this.inputs.srcVideo)
      EventBus.$emit('appendTo',this.inputs.srcVideo)
    }
  }

  this.onTick = function(delta){
    const THREE = this.context.three;
    if (this.outputs.objectRoot) {
      this.outputs.objectRoot.quaternion.copy( this.context.camera.quaternion );
    }
  };

  this.onInputsUpdated = function(){
    //this.plane3.visible = this.inputs.boxshow
  };
}
