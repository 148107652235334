import EventBus from './Events'
export function Badge(){
  this.events= {
    "INTERACTION.CLICK": true,
    "INTERACTION.HOVER": false,
    "INTERACTION.DRAG": false
  }
  this.eventType= 'INTERACTION.CLICK';
  this.inputs = {
    srcFbx: null,
    srcVideo: null,
    srcImage: null,
    srcTextVideoTitle: null,
    localPosition: null,
    localScale: null,
    videoPlayed: false,
    srcTextNext: null,
    linkTo: null,
    srcTextNextIcon: null
  };
  this.outputs = {
    texture: null,
    painter: null
  }

  this.onInit = function() {
    const THREE = this.context.three;
    var textureText = new THREE.TextureLoader().load(this.inputs.srcImage);
    textureText.mapping = THREE.ClampToEdgeWrapping;

    var geometry = new THREE.PlaneGeometry( 1, 1, 1 );
    var material = new THREE.MeshBasicMaterial( {color:0x000000, transparent:true, opacity:0.45} );
    var plane = new THREE.Mesh( geometry, material );

    var geometry = new THREE.PlaneGeometry( 0.9, 0.9, 1 );
    var material = new THREE.MeshBasicMaterial( {map:textureText,transparent:true} );
    var plane2 = new THREE.Mesh( geometry, material );
    plane2.position.set( 0.01, 0, 0 );

    var group = new THREE.Group();
    group.add(plane);
    group.add(plane2);

    group.position.x = this.inputs.localPosition.x
    group.position.y = this.inputs.localPosition.y
    group.position.z = this.inputs.localPosition.z
    group.scale.x = this.inputs.localScale.x
    group.scale.y = this.inputs.localScale.y
    group.scale.z = this.inputs.localScale.z

    group.quaternion.copy( this.context.camera.quaternion );

    this.outputs.objectRoot = group;
    this.outputs.collider = group;
  };
  this.onEvent = function(payload,data) {
    if(payload=='INTERACTION.CLICK'){
        EventBus.$emit('moveTo',this.inputs.linkTo)
    }
  }

  this.onTick = function(delta){
    const THREE = this.context.three;
    if (this.outputs.objectRoot) {
      this.outputs.objectRoot.quaternion.copy( this.context.camera.quaternion );
    }
  };

  this.onInputsUpdated = function(){
    
  };
}
