import EventBus from './Events'
export function BadgeExpand(){
  this.events= {
    "INTERACTION.CLICK": true,
    "INTERACTION.HOVER": false,
    "INTERACTION.DRAG": false
  }
  this.eventType= 'INTERACTION.CLICK';
  this.inputs = {
    srcFbx: null,
    srcVideo: null,
    srcImage: null,
    srcTextVideoTitle: null,
    localPosition: null,
    localScale: null,
    boxshow: false,
    srcTextNext: null,
    linkTo: null,
    srcTextNextIcon: null,
    open: null,
    text: null,
    movieTo: null,
    rotate:null,
    appendTo:null
  };
  this.outputs = {
    texture: null,
    painter: null
  }

  this.onInit = function() {
    const THREE = this.context.three;
    var loader = new THREE.FBXLoader();
    loader.load(this.inputs.srcFbx, (obj) => {
      var textureText = new THREE.TextureLoader().load(this.inputs.srcImage);
      textureText.mapping = THREE.ClampToEdgeWrapping;

      if(this.inputs.srcImage.includes("exit") || this.inputs.srcImage.includes("enter")){
        var trasparente = false;
      }else{
        var trasparente = true;
      }

      var m1 = new THREE.MeshBasicMaterial({map: textureText,transparent:trasparente}); //LinkText moveTo
      if(this.inputs.appendTo!=null){
        var m2 = new THREE.MeshBasicMaterial({opacity:0,transparent:trasparente}); //Sfondo
      }else{
        var m2 = new THREE.MeshBasicMaterial({color: 0x000000,opacity:0.6, transparent:trasparente}); //Sfondo
      }
      
      var materiali = new Array(m1,m2);
      var i = 0;
      obj.traverse( function ( child ) {
        if ( child.isMesh ) {
          child.material= materiali[i];
          i++
        }
      })

      obj.position.x = this.inputs.localPosition.x
      obj.position.y = this.inputs.localPosition.y
      obj.position.z = this.inputs.localPosition.z
      obj.scale.x = this.inputs.localScale.x
      obj.scale.y = this.inputs.localScale.y
      obj.scale.z = this.inputs.localScale.z

      obj.quaternion.copy( this.context.camera.quaternion );
    
      this.outputs.objectRoot = obj;
      this.outputs.collider = obj;
    });
  };
  this.onEvent = function(payload,data) {
    if(payload=='INTERACTION.CLICK'){
        /*if(data.collider.name=='badgeContainer' && this.inputs.open){
          var div = '<div id="info-inner">'+this.inputs.text+'<br><br><span>Close</span></div>';
          EventBus.$emit('appendTo',this.inputs.text)
        }*/
        if(this.inputs.linkTo !=''){
          if(this.inputs.rotate){
            var dati = {"sid":this.inputs.linkTo,"coord":this.inputs.rotate}
          }else{
            var dati = {"sid":this.inputs.linkTo}
          }
          EventBus.$emit('moveTo',dati)
        }
        if(this.inputs.appendTo!=null){
          EventBus.$emit('appendTo',this.inputs.appendTo)
        }
        if(this.inputs.movieTo !=''){
          EventBus.$emit('movieTo',this.inputs.movieTo,this.inputs.linkTo)
        }
    }
  }

  this.onTick = function(delta){
    const THREE = this.context.three;
    if (this.outputs.objectRoot) {
      this.outputs.objectRoot.quaternion.copy( this.context.camera.quaternion );
    }
  };

  this.onInputsUpdated = function(){
    //this.plane3.visible = this.inputs.boxshow
  };

  this.wrapText = function(context, text, x, y, maxWidth, lineHeight) {
    var words = text.split(' ');
    var line = '';

    for(var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + ' ';
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
      }
      else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
  }
}
