<template>
  <div class="home">
    <div id="loading" v-if="loading">
      <div class="logo-preload">
        <img src="../assets/loading.jpg" class="flip flip-r">
      </div>
    </div>
    <div class="prehome" v-show="!startTour && !logged">
      <div style="color:#FFF;background:rgba(0,0,0,0.6);width:400px;height:200px;position:absolute;top:50%;left:50%;margin-top:-100px;margin-left:-200px;box-sizing:border-box;padding:40px;">
        <label v-if="language=='it'">Indirizzo Email</label>
        <label v-if="language=='en'">Email address</label>
        <p v-if="language=='it' && errorlog" style="color:red;">Inserire un indirizzo email valido</p>
        <p v-if="language=='en' && errorlog" style="color:red;">Please, insert an valid email address</p>
        <input type="text" v-model="email" class="email">
        <button class="buttonstart" @click="changeStart">{{label}}</button>
      </div>
    </div>
    <!--
    <div class="prehome" v-show="!startTour && logged">
      <span class="start" @click="changeStart">{{label}}</span>
    </div>-->
    <div class="container" >
      <div class="showcase_container">
          <iframe class="showcase" contenteditable="true" frameborder="0" allow="xr-spatial-tracking; fullscreen; camera; microphone; autoplay" id="showcase" ref="showcase" :src="'bundle/showcase.html?m=' + modelId+'&help=0&play=1&qs=1&gt=1&hr=1&applicationKey=0q1z18uxugcn2739gbihdk49d'" style="width:100%;height:100%;"></iframe>
      </div>
      <div class="panel right">
        <div class="containerMenu" @click="openMenu">
          <span>Menu virtual tour</span> <span><img src="../assets/menu.svg"></span>
        </div>
        <div class="listMenu" v-show="dislayMenu">
        <ul>
          <li v-for="(item,indexcat) in menu" :key="item.id" :class="(!!item.mode)? '' :'line margin'" @click="getRoute(indexcat)" v-if="item.show" v-html="item.label"></li>
        </ul>
        </div>
      </div>
      <div id="lightgallery-pre" :class="on">
        <div class="contentg">
          <div id="lightgallery">
            <a :href="domain+'gallery/1-etched_lead.jpg'"><img :src="domain+'gallery/1-etched_lead.jpg'"></a>
            <a :href="domain+'gallery/2-onix_steel.jpg'"><img :src="domain+'gallery/2-onix_steel.jpg'"></a>
            <a :href="domain+'gallery/3-weathered_bronze.jpg'"><img :src="domain+'gallery/3-weathered_bronze.jpg'"></a>
            <a :href="domain+'gallery/4-rustic_bronze.jpg'"><img :src="domain+'gallery/4-rustic_bronze.jpg'"></a>
            <a :href="domain+'gallery/5-worn_steel.jpg'"><img :src="domain+'gallery/5-worn_steel.jpg'"></a>
            <a :href="domain+'gallery/6-faded_steel.jpg'"><img :src="domain+'gallery/6-faded_steel.jpg'"></a>
            <a :href="domain+'gallery/7-natural_concrete.jpg'"><img :src="domain+'gallery/7-natural_concrete.jpg'"></a>
            <a :href="domain+'gallery/8-carbon_steel.jpg'"><img :src="domain+'gallery/8-carbon_steel.jpg'"></a>
            <a :href="domain+'gallery/9-charcoal_bronze.jpg'"><img :src="domain+'gallery/9-charcoal_bronze.jpg'"></a>
            <a :href="domain+'gallery/10-industrial_iron.jpg'"><img :src="domain+'gallery/10-industrial_iron.jpg'"></a>
            <a :href="domain+'gallery/11-white_chalk.jpg'"><img :src="domain+'gallery/11-white_chalk.jpg'"></a>
            <a :href="domain+'gallery/12-historic_copper.jpg'"><img :src="domain+'gallery/12-historic_copper.jpg'"></a>
            <a :href="domain+'gallery/13-raw_steel.jpg'"><img :src="domain+'gallery/13-raw_steel.jpg'"></a>
            <a :href="domain+'gallery/14-rusted_steel.jpg'"><img :src="domain+'gallery/14-rusted_steel.jpg'"></a>
            <a :href="domain+'gallery/15-gun_metal.jpg'"><img :src="domain+'gallery/15-gun_metal.jpg'"></a>
          </div>
          <span class="closeinfo" @click="closeinfo">X</span>
        </div>
      </div>
      <a class="language" v-show="startTour && language=='it'" href="en"><img src="../assets/en.png"></a>
      <a class="language" v-show="startTour && language=='en'" href="it"><img src="../assets/it.png"></a>
      <a class="mappa" v-show="startTour" @click="openMap"><img src="../assets/map.png"></a>
      <div id="info" ref="info" v-show="overlay"><div id="info-inner"><div class="organigramma" :class="{'video':videoDiv}"><div v-html="html"></div><span class="closeinfo" @click="closeinfo">X</span></div></div></div>
      <div class="click-overlay" v-show="overlay" ref="overlaydiv"></div>
      <div class="map-overlay" v-show="overlay2">
        <div id="info-inner2">
          <div class="map">
            <img :src="mappa">
            <div style="position:absolute;width:15%;height:25%;top:25%;left:3%;cursor:pointer;" @click="getRoute(21)"></div>
            <div style="position:absolute;width:9%;height:12%;top:65%;left:20%;cursor:pointer;" @click="getRoute(1)"></div>
            <div style="position:absolute;width:9%;height:12%;top:52%;left:20%;cursor:pointer;" @click="getRoute(2)"></div>
            <div style="position:absolute;width:5%;height:12%;top:52%;left:29.1%;cursor:pointer;" @click="getRoute(10)"></div>
            <div style="position:absolute;width:15%;height:11%;top:53%;left:35%;cursor:pointer;" @click="getRoute(11)"></div>
            <div style="position:absolute;width:15%;height:11%;top:65%;left:31%;cursor:pointer;" @click="getRoute(12)"></div>
            <div style="position:absolute;width:20%;height:11%;top:53%;left:54%;cursor:pointer;" @click="getRoute(0,'06de6502dacf4f02864962be7316d1c4')"></div>
            <div style="position:absolute;width:22%;height:11%;top:65%;left:48%;cursor:pointer;" @click="getRoute(13)"></div>
            <div style="position:absolute;width:20%;height:18.5%;top:26%;left:30.5%;cursor:pointer;" @click="getRoute(17)"></div>
            <div style="position:absolute;width:6%;height:6.5%;top:27%;left:55.5%;cursor:pointer;" @click="getRoute(19)"></div>
            <div style="position:absolute;width:8%;height:6.5%;top:26%;left:64%;cursor:pointer;" @click="getRoute(18)"></div>
            <div style="position:absolute;width:15%;height:6%;top:38.5%;left:58%;cursor:pointer;" @click="getRoute(20)"></div>
            <div style="position:absolute;width:15%;height:11%;top:65%;left:82%;cursor:pointer;" @click="getRoute(14)"></div>
            <div style="position:absolute;width:9%;height:20%;top:40%;left:84%;cursor:pointer;" @click="getRoute(15)"></div>
            <div style="position:absolute;width:2%;height:8%;top:38%;left:93%;cursor:pointer;" @click="getRoute(16)"></div><!---->
            <span class="closeinfo" @click="closeinfo2">X</span>
          </div>
        </div>
      </div>
      <div v-bind:class="{'layerciao':layer}" id="layerciao" v-show="taglayer"><div class="tag-position billboard-standard-view" style="width:310px;" :style="`left:${ this.activeX }px;top:${ this.activeY }px`"><div class="tag-wrapper active"><div class="tag-container tag-right"><div class="billboard-body expandable"><div class="billboard-content"><div class="tag-text-content noScroll None" style="height: auto;"><div class="outerScrollbarWrapper verticalOnly scrollbar-mattertag-text-content"><div class="innerScrollbarWrapper"><div class="paddingScrollbarWrapper"><div class="tag-label"><span>{{labelm}}</span></div><div class="tag-description">{{descm}}</div></div></div><div></div></div></div></div></div></div></div></div></div>
    </div><!--style="transform: translate(-51.968831353027596px, 220px);":style="`left:${ this.activeX }px;top:${ this.activeY }px`"-->
  </div>
</template>

<script>
import axios from "axios";
import {VideoYt} from '../includes/VideoYt'
import {VideoSimple} from '../includes/VideoSimple'
import {Badge} from '../includes/Badge'
import {BadgeExpand} from '../includes/BadgeExpand'
import {BadgeExpand2} from '../includes/BadgeExpand2'
import {MyTag} from '../includes/MyTag'
import EventBus from '../includes/Events'

import jsonIT from '../assets/json/app_it.json'
import jsonEN from '../assets/json/app_en.json'

import menujsonIt from '../assets/json/menu_it.json'
import menujsonEn from '../assets/json/menu_en.json'

import tagsIt from '../assets/json/mattertag_it.json'
import tagsEn from '../assets/json/mattertag_en.json'

import labels from '../assets/json/label.json'

import $ from 'jquery'
import { isIOS } from 'mobile-device-detect';

import 'lightgallery.js'
import 'lg-zoom.js';
import 'lg-fullscreen.js';
import 'lg-thumbnail.js';
import 'lg-autoplay.js';

import 'lightgallery.js/dist/css/lightgallery.css'

export default {
  name: 'Home',
  components:{
    
  },
  data(){
    return{
      errorlog: false,
      email:'',
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      on:'',
      logged: false,
      loading:true,
      startTour: false,
      label: '',
      labels: labels,
      sdk: '',
      mute: false,
      html: '',
      overlay: false,
      overlay2: false,
      modelId: '6QuriAVpz57',//6QuriAVpz57 H8wEBPcTivW v5vbATF3jYW
      myJson: '',
      dislayMenu: false,
      domain: 'https://360tour.discoverbrombal.com/',//https://www.ubis.com/brombal/
      menu: '',
      language: 'en',
      tags : '',
      stop: false,
      mobile: isIOS,
      coords: null,
      videoDiv: false,
      mappa: '',
      activeX:0,
      activeY:0,
      activeXY: '',
      taglayer: false,
      layer: false,
      labelm: '',
      descm: '',
      poseCache: {x:0,y:0,z:0},
      dove: ''
    }
  },
  mounted(){
    var lang = this.$route.params.lang
    if(lang){

    }else{
      lang = navigator.language;
    }
    if(lang=='it' || lang=='it-IT' || lang=='it-it'){
      this.language = 'it';
      this.myJson = jsonIT;
      this.label = this.labels.it
      this.menu = menujsonIt;
      this.tags = tagsIt;
      this.mappa = this.domain+'texture/mappa_it.jpg'
    }else{
      this.language = 'en';
      this.myJson = jsonEN;
      this.label = this.labels.en
      this.menu = menujsonEn;
      this.tags = tagsEn;
      this.mappa = this.domain+'texture/mappa_en.jpg'
    }
    this.label = this.label[0].start

    if(this.$browserDetect.isSafari || this.mobile){
      this.mute = true
    }
    
    this.$refs.showcase.addEventListener('load', this.showcaseLoader);
    EventBus.$on('moveTo', async (data) => {
      var self = this;
      this.sdk.Sweep.moveTo(data.sid).then(function(){
        if(data.coord){
          self.rotation(data.coord)
        }
        self.sdk.Sweep.current.subscribe(function (currentSweep) {
          // Change to the current sweep has occurred.
          if (currentSweep.sid === '') {
            console.log('Not currently stationed at a sweep position');
          } else {
            console.log('Currently at sweep', currentSweep.sid);
            console.log('Current position', currentSweep.position);
            console.log('On floor', currentSweep.floorInfo.sequence);
          }
        });
      })
    })
    EventBus.$on('appendTo', async (data) => {
      this.overlay = true;
      this.videoDiv = false;
      if(data=='organigramma'){
        this.html = '<img src="'+this.domain+'texture/organigramma.jpg">';
      }else if(data=='gallery'){
        this.on = 'on';
        const el = document.getElementById('lightgallery')
        window.lightGallery(el)
      }else if(data.type=='video'){
        this.videoDiv = true;
        this.html = '<video ref="video" controls poster="'+data.cover+'"><source src="'+data.src+'" type="video/mp4"></video>';
      }else{
        this.html = data;
      }
    })
    EventBus.$on('movieTo', async (data,coord) => {
      this.coords = coord;
      this.modelId = data;
    })
    EventBus.$on('addTagLayer',async (data,payload,e) => {
      if(data.label!='' || data.description!=''){
        this.descm = data.description
        this.labelm = data.label
        this.activeX = 0
        var vn = this
        var showcase = document.getElementById('showcase');
        var showcaseSize = {
          w: showcase.clientWidth,
          h: showcase.clientHeight,
        };
        
        //alert(this.$isMobile())
        if(this.$isMobile()){
          //alert('ciao')
          var coord = this.sdk.Conversion.worldToScreen(data.point, this.poseCache, showcaseSize)
          this.taglayer = true;
          console.log(coord)
          if(Math.sign(coord.x)==-1){
            this.activeX = coord.x * -1
          }else{
            this.activeX = coord.x
          }
          if(Math.sign(coord.y)==-1){
            this.activeY = coord.y * -1
          }else{
            this.activeY = coord.y
          }
        }
        $(this.$refs.showcase).contents().find('html').on('mousemove', function (e) {
            var offsetLeft = e.pageX - $("body").scrollLeft();
            var offsetTop = e.pageY - $("body").scrollTop();
            if((parseInt(offsetLeft)+310) > showcaseSize.w){
              offsetLeft -= 310;
            }
            vn.activeX = offsetLeft
            vn.activeY = offsetTop
            vn.taglayer = true
        })
      }
    })
    EventBus.$on('addTagLayerStop',async (data) => {
      this.taglayer = false
      $(this.$refs.showcase).contents().find('html').unbind('mousemove')
    })
  },
  watch: {
    modelId: function (newValue, oldValue) {
      console.log('TO '+newValue)
      console.log('FROM '+oldValue)
      //this.setLogo();
      //this.showcaseLoader()
    }
  },
  methods:{
    isEmailValid: function() {
      return (this.email == "")? "" : (this.reg.test(this.email)) ? true : false;
    },
    getMouse(event) {
      this.activeX = event.pageX
      this.activeY = event.pageY
    },
    rotation(val){
      this.sdk.Camera.rotate(val)
    },
    getRoute(id,dove=''){
      this.overlay2 = false;
      if(dove==''){
        this.dove = '';
        if(this.modelId == this.menu[id].mode){
          this.coords = '';
          var self = this;
          this.sdk.Sweep.moveTo(this.menu[id].coord).then(function() {
            self.sdk.Sweep.current.subscribe(function (currentSweep) {
              console.log('Currently at sweep', currentSweep.sid);
              console.log('Current position', currentSweep.position);
              console.log('On floor', currentSweep.floorInfo.sequence);
            });
          })
        }else{
          this.modelId = this.menu[id].mode
          this.coords = id
        }
        this.dislayMenu = false
        //this.dislayMenu = !this.dislayMenu
      }else{
        if(this.modelId == '6QuriAVpz57'){
          var self = this;
          this.sdk.Sweep.moveTo(dove).then(function() {
            self.sdk.Sweep.current.subscribe(function (currentSweep) {
              console.log('Currently at sweep', currentSweep.sid);
              console.log('Current position', currentSweep.position);
              console.log('On floor', currentSweep.floorInfo.sequence);
            });
          })
        }else{
          this.modelId = '6QuriAVpz57'
          this.coords = ''
          this.dove = dove
        }
      }
    },
    async showcaseLoader(){
      try {
        this.sdk = await this.$refs.showcase.contentWindow.MP_SDK.connect(this.$refs.showcase, '0q1z18uxugcn2739gbihdk49d', '3.11')
      }
      catch(e) {
        console.error(e);
      }
      var vnpos = this
      this.sdk.Camera.pose.subscribe(function(pose) {
        vnpos.poseCache = pose;
      })
      this.sdk.Scene.register('mp.videoyt', this.myVideoYt)
      this.sdk.Scene.register('mp.videosimple', this.myVideoSimple)
      this.sdk.Scene.register('mp.badge', this.myBadge)
      this.sdk.Scene.register('mp.badgeexpand', this.myBadgeExpand)
      this.sdk.Scene.register('mp.badgeexpand2', this.myBadgeExpand2)
      this.sdk.Scene.register('mp.mytag', this.myTag)
      this.loadedShowcaseHandler()
    },
    closeinfo(){
      this.overlay = false;
      this.html = ''
      this.on = ''
    },
    openMap(){
      this.overlay2 = true;
    },
    closeinfo2(){
      this.overlay2 = false;
    },
    async loadedShowcaseHandler(){
      const sdk = this
      this.sdk.Model.getData()
      .then(function() {
        sdk.loading = false

      })
      .catch(function(error) { });
      
      /*Object.entries(this.tags.tags).forEach(async (entry) => {
        const [key, value] = entry;
        if(this.modelId == value.modelId){
          this.sdk.Mattertag.registerIcon(value.icon, this.domain+value.srcIcon);
          this.sdk.Mattertag.add([{
            label: value.label,
            description: value.description,
            anchorPosition: value.ancor,
            stemVector: {x: 0,y: 0,z: 0,},
            color: {r: 0.0,g: 0.0,b: 1.0, }
          }]).then((sid) => {
            this.sdk.Mattertag.editIcon(sid[0], value.icon);
            if(value.label==null || value.label==undefined){
              this.sdk.Mattertag.preventAction(sid[0], {
                opening: true,
              });
            }
          })
        }
      });*/

      Object.entries(this.myJson.payload.objects).forEach(async (entry) => {
        const [key, value] = entry;
        if(value.type=='mp.badgeexpand' && this.modelId == value.thisMovie){
          const node = await this.sdk.Scene.createNode();
          node.addComponent('mp.badgeexpand', { 
            eventsEnabled: true, 
            userNavigationEnabled: false,
            open: value.open,
            text: value.text,
            srcFbx: this.domain+value.srcFbx,
            srcImage: this.domain+value.srcImage,
            localPosition: value.localPosition,
            localScale: value.localScale,
            linkTo: value.linkTo,
            movieTo: value.movieTo,
            rotate: value.rotate,
            appendTo: value.appendTo,
          });
          node.start();
        }
        if(value.type=='mp.badgeexpand2' && this.modelId == value.thisMovie){
          const node = await this.sdk.Scene.createNode();
          node.addComponent('mp.badgeexpand2', { 
            eventsEnabled: true, 
            userNavigationEnabled: false,
            localPosition: value.localPosition,
            localScale: value.localScale,
            linkTo: value.linkTo,
            movieTo: value.movieTo
          });
          node.start();
        }
        if(value.type=='mp.videosimple' && this.modelId == value.thisMovie){
          const node = await this.sdk.Scene.createNode();
          node.addComponent('mp.videosimple', { 
            eventsEnabled: true, 
            eventStop:this.stop,
            mute: this.mute,
            userNavigationEnabled: false,
            srcFbx: this.domain+value.srcFbx,
            srcVideo: this.domain+value.srcVideo,
            srcImage: this.domain+value.srcImage,
            srcTextVideoTitle: this.domain+value.srcTextVideoTitle,
            localPosition: value.localPosition,
            srcTextPlayIcon: this.domain+value.srcTextPlayIcon,
            localScale: value.localScale,
            external: value.external,
            rotation: value.rotation,
          });
          node.start();
        }
        if(value.type=='mp.mytag' && this.modelId == value.thisMovie){
          const node = await this.sdk.Scene.createNode();
          node.addComponent('mp.mytag', { 
            eventsEnabled: true, 
            userNavigationEnabled: false,
            open: value.open,
            isMobile: this.$isMobile(),
            label: value.label,
            description: value.description,
            srcFbx: this.domain+value.srcFbx,
            srcImage: this.domain+value.srcImage,
            localPosition: value.localPosition,
            localScale: value.localScale
          });
          node.start();
        }
        if(value.type=='mp.videoyt' && this.modelId == value.thisMovie){
          const node = await this.sdk.Scene.createNode();
          node.addComponent('mp.videoyt', { 
            eventsEnabled: true, 
            userNavigationEnabled: false,
            open: value.open,
            srcFbx: this.domain+value.srcFbx,
            srcImage: this.domain+value.srcImage,
            srcIcon: this.domain+value.srcIcon,
            srcTextVideoTitle: this.domain+value.srcTextVideoTitle,
            srcVideo: value.srcVideo,
            localPosition: value.localPosition,
            localScale: value.localScale,
            movieTo: value.movieTo,
          });
          node.start();
        }
      });
      this.setLogo()
    },
    //myVideo (){ return new Video(); },
    myVideoYt (){ return new VideoYt(); },
    myVideoSimple (){ return new VideoSimple(); },
    myBadge (){ return new Badge(); },
    myBadgeExpand (){ return new BadgeExpand(); },
    myBadgeExpand2 (){ return new BadgeExpand2(); },
    myTag (){ return new MyTag(); },
    openMenu (){ 
      this.dislayMenu = !this.dislayMenu
    },
    changeStart (){
      if(this.isEmailValid()){
        axios({
          url: 'https://360tour.discoverbrombal.com/save.php',
          method: 'POST',
          data: "email="+this.email
        })
        .then(resp => {
          this.errorlog = false
          this.startTour = !this.startTour
        })
        .catch(err => {
          console.log(err)
        });
      }else{
        this.errorlog = true
      }      
    },
    setLogo (){
      this.dislayMenu = false
      var vn = this;
      setTimeout(function(){
        if(vn.coords!=null){
          console.log('COORD '+vn.coords)
          console.log('DOVE '+vn.dove)
          vn.getRoute(vn.coords,vn.dove)
        }
      },1000,this);
      setTimeout(function(){
        $(vn.$refs.showcase).contents().find('#gui-name').html('<img src="'+vn.domain+'img/logo.png" style="display:block;height:64px;margin:0 auto;">')
        $("#showcase").contents().find('#title-toggle').click(function(){
          setTimeout(function(){
            console.log($(vn.$refs.showcase).contents().find('#gui-name').html())
            $(vn.$refs.showcase).contents().find('#gui-name').html('<img src="'+vn.domain+'img/logo.png" style="display:block;height:64px;margin:0 auto;">')
          },500)
        })
      },3000,this);
    }
  }
}
</script>
