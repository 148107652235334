import EventBus from './Events'
export function MyTag(){
  this.events= {}
  //this.eventType= 'INTERACTION.UNHOVER';
  this.inputs = {
    srcFbx: null,
    srcImage: null,
    label: null,
    description: null,
    localPosition: null,
    localScale: null,
    open: false,
    evento: false,
    isMobile: false,
  };
  this.outputs = {
    texture: null,
    painter: null
  }
  if(this.inputs.isMobile){
    this.events= {
      "INTERACTION.CLICK": true,
      "INTERACTION.HOVER": false,
      "INTERACTION.UNHOVER": false,
      "INTERACTION.DRAG": true,
      "INTERACTION.PINCH": true,
      "INTERACTION.PINCH_END": true
    }
  }else{
    this.events= {
      "INTERACTION.CLICK": true,
      "INTERACTION.HOVER": true,
      "INTERACTION.UNHOVER": true,
      "INTERACTION.DRAG": true,
      "INTERACTION.PINCH": true,
      "INTERACTION.PINCH_END": true,
      "INTERACTION.POINTER_MOVE": true,
      "INTERACTION.POINTER_BUTTON": true
    }
  }
  this.onInit = function() {
    const THREE = this.context.three;
    var loader = new THREE.FBXLoader();
    loader.load(this.inputs.srcFbx, (obj) => {
      var textureText = new THREE.TextureLoader().load(this.inputs.srcImage);
      textureText.mapping = THREE.ClampToEdgeWrapping;

      var m1 = new THREE.MeshBasicMaterial({map: textureText,transparent:true}); //LinkText moveTo
      var m2 = new THREE.MeshBasicMaterial({color: 0x000000,opacity:0.6, transparent:true}); //Sfondo
      
      var materiali = new Array(m1,m2);
      var i = 0;
      obj.traverse( function ( child ) {
        if ( child.isMesh ) {
          child.material= materiali[i];
          i++
        }
      })

      obj.position.x = this.inputs.localPosition.x
      obj.position.y = this.inputs.localPosition.y
      obj.position.z = this.inputs.localPosition.z
      obj.scale.x = this.inputs.localScale.x
      obj.scale.y = this.inputs.localScale.y
      obj.scale.z = this.inputs.localScale.z

      obj.quaternion.copy( this.context.camera.quaternion );
    
      this.outputs.objectRoot = obj;
      this.outputs.collider = obj;


    });
  };
  this.onEvent = function(payload,data) {
    data.label = this.inputs.label
    data.description = this.inputs.description
    //console.log('mobile'+)
    if(!this.inputs.isMobile && payload=='INTERACTION.CLICK'){

    }else{
      if(!this.inputs.evento){
        EventBus.$emit('addTagLayer',data,payload)
      }else{
        EventBus.$emit('addTagLayerStop',data)
      }
      this.inputs.evento = !this.inputs.evento
    }
  }

  this.onTick = function(delta){
    const THREE = this.context.three;
    if (this.outputs.objectRoot) {
      this.outputs.objectRoot.quaternion.copy( this.context.camera.quaternion );
    }
  };

  this.onInputsUpdated = function(){
    //this.inputs.evento = !this.inputs.evento
    //this.plane3.visible = this.inputs.boxshow
  };
}
