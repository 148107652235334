import EventBus from './Events'
export function VideoSimple(){
  this.events= {
    "INTERACTION.CLICK": true,
    "INTERACTION.HOVER": false,
    "INTERACTION.DRAG": false
  }
  this.eventType= 'INTERACTION.CLICK';
  this.inputs = {
    srcFbx: null,
    srcVideo: null,
    srcImage: null,
    srcTextVideoTitle: null,
    localPosition: null,
    localScale: null,
    videoPlayed: false,
    srcTextPlayIcon: null,
    rotation: null,
    mute: null,
    external: null,
    eventStop: null,
  };
  this.outputs = {
    texture: null,
    painter: null
  }

  this.onInit = function() {
    const THREE = this.context.three;
    var loader = new THREE.FBXLoader();
    loader.load(this.inputs.srcFbx, (obj) => {
      /*if(this.inputs.external){
        this.video = document.createElement('video');
        this.video.autoplay  = false;
        this.video.controls = true;
        this.video.setAttribute('playsinline','');
        this.video.setAttribute('crossorigin', 'anonymous');
        this.video.src = this.inputs.srcVideo;
        this.video.poster=this.inputs.srcImage
        this.video.muted = this.inputs.mute;
        this.textureVideo = new THREE.VideoTexture( this.video );
      }*/

      var textureImage = new THREE.TextureLoader().load(this.inputs.srcImage);
      textureImage.mapping = THREE.ClampToEdgeWrapping;
      
      var textureText = new THREE.TextureLoader().load(this.inputs.srcTextVideoTitle);
      textureText.mapping = THREE.ClampToEdgeWrapping;

      var textureNext = new THREE.TextureLoader().load(this.inputs.srcTextNext);
      textureNext.mapping = THREE.ClampToEdgeWrapping;

      var textureNextIcon = new THREE.TextureLoader().load(this.inputs.srcTextPlayIcon);
      textureNextIcon.mapping = THREE.ClampToEdgeWrapping;
         
      const m1 = new THREE.MeshBasicMaterial({map: textureNextIcon,transparent:true}); //icon Link
      const m2 = new THREE.MeshBasicMaterial({map: textureText,transparent:true}); //LinkText moveTo
      const m3 = new THREE.MeshBasicMaterial({map:textureImage});
      const m4 = new THREE.MeshBasicMaterial({color: 0x000000,opacity:0.6, transparent:true}); //Sfondo
      
      this.textureImage = textureImage

      var materiali = new Array(m1,m2,m3,m4)
      var i = 0;
      obj.traverse( function ( child ) {
        if ( child.isMesh ) {
          child.material= materiali[i];
          i++
        }
      })

      /*var hs = this;
      document.getElementById('play').addEventListener('click',function(){
        hs.video.muted = !hs.video.muted
      })*/
     
      obj.position.x = this.inputs.localPosition.x
      obj.position.y = this.inputs.localPosition.y
      obj.position.z = this.inputs.localPosition.z
      obj.scale.x = this.inputs.localScale.x
      obj.scale.y = this.inputs.localScale.y
      obj.scale.z = this.inputs.localScale.z

      obj.quaternion.copy( this.context.camera.quaternion );
    
      this.outputs.objectRoot = obj;
      this.outputs.collider = obj;
    })
  };
  this.onEvent = function(payload,data) {
    if(payload=='INTERACTION.CLICK'){
      /*if(this.inputs.external){
        var dati = {"type":"video","src":this.inputs.srcVideo,"cover":this.inputs.srcImage}
        EventBus.$emit('appendTo',dati)
      }
      if(!this.inputs.external){
        this.inputs.videoPlayed = !this.inputs.videoPlayed
      }*/
      var dati = {"type":"video","src":this.inputs.srcVideo,"cover":this.inputs.srcImage}
      EventBus.$emit('appendTo',dati)
    }
  }

  this.onTick = function(delta){
    const THREE = this.context.three;
    if (this.outputs.objectRoot) {
      this.outputs.objectRoot.quaternion.copy( this.context.camera.quaternion );
    }
    /*if(this.inputs.videoPlayed && !this.inputs.external){
      if(this.video.ended){
        this.inputs.videoPlayed = false;
        this.video.currentTime = 0;
        this.outputs.objectRoot.children[2].material.map = this.textureImage;
      }
    }*/
  };

  this.onInputsUpdated = function(){
    //this.outputs.objectRoot.children[2].material.map = this.inputs.videoPlayed ? this.textureVideo : this.textureImage
    if(this.inputs.videoPlayed && !this.inputs.external){
      this.video.currentTime = 0;
      setTimeout( ()=> {
        this.video.play();
      }, 10,this);
    }else{
      this.video.pause()
    }
  };
}
