import EventBus from './Events'
export function BadgeExpand2(){
  this.events= {
    "INTERACTION.CLICK": true,
    "INTERACTION.HOVER": false,
    "INTERACTION.DRAG": false
  }
  this.eventType= 'INTERACTION.CLICK';
  this.inputs = {
    localPosition: null,
    localScale: null,
    linkTo: null,
    movieTo: null,
    color: '0xFF0000',
  };
  this.outputs = {
    texture: null,
    painter: null
  }

  this.onInit = function() {
    const THREE = this.context.three;
    console.log(this.inputs.color)
    var geometry = new THREE.PlaneGeometry( 1, 1, 1 );
    var material = new THREE.MeshBasicMaterial( {color:0xFF0000, transparent:true, opacity:0,side: THREE.DoubleSide} );//side: THREE.DoubleSide
    var plane = new THREE.Mesh( geometry, material );

    var group = new THREE.Group();
    group.add(plane);

    group.position.x = this.inputs.localPosition.x
    group.position.y = this.inputs.localPosition.y
    group.position.z = this.inputs.localPosition.z
    group.scale.x = this.inputs.localScale.x
    group.scale.y = this.inputs.localScale.y
    group.scale.z = this.inputs.localScale.z

    //group.quaternion.copy( this.context.camera.quaternion );

    this.outputs.objectRoot = group;
    this.outputs.collider = group;
  };
  this.onEvent = function(payload,data) {
    if(payload=='INTERACTION.CLICK'){
      EventBus.$emit('movieTo',this.inputs.movieTo,this.inputs.linkTo)
    }
  }

  this.onTick = function(delta){
    const THREE = this.context.three;
    if (this.outputs.objectRoot) {
      //this.outputs.objectRoot.quaternion.copy( this.context.camera.quaternion );
    }
  };

  this.onInputsUpdated = function(){
    
  };
}